import request from "@/utils/request";

// 获取banner图片
export function startImg() {
  return request({
    url: "/ins-user-api/activity/activityList",
    method: "post",
  });
}

// 发送验证码请求
export function sendVerificationCode(data) {
  return request({
    url: "/ins-wbo/login/sendVerificationCode",
    method: "post",
    params: data,
  });
}

// 用户注册
export function registeredUser(data) {
  return request({
    url: "/ins-wbo/user/register",
    method: "post",
    data,
  });
}

// 新用户礼品选择列表
export function giftList() {
  return request({
    url: "/ins-user-api/activityGif/giftList",
    method: "post"
  });
}

// 用户选择礼品
export function userGift(data) {
  return request({
    url: "/ins-user-api/user-table/userGift",
    method: "post",
    data
  });
}