<template>
  <el-container>
    <el-header>
      <label class="title">{{ $t('chooseReward') }}</label>
      <div class="lang_div">
        <span class="cn" :class="{ active: language == 'cn' }" @click="chooseChinese()">中文</span>
        | <span class="en" :class="{ active: language == 'en' }" @click="chooseEnglish()">EN</span>
      </div>
    </el-header>
    <el-main class="list">
      <el-row>
        <!-- 水晶球 -->
        <el-col :span="12">
          <el-card class="gift_card" @click.native="list[0]?.total == 0 ? false : select(1)"
            :class="{ active: selected == 1 }">
            <div class="img1">
              <img v-if="list[0]?.total == 0" src="../assets/images/Rectangle.png" alt="" />
              <span v-if="list[0]?.total == 0">{{ $t('fullyRedeem') }}</span>
            </div>
            <label for="" class="title" style="height:38px;line-height:38px;">{{ $t('surpriseBox') }}</label>
            <label for="" class="count">{{ $t('avaliable') }}: {{ list[0]?.total }}</label>
          </el-card>
        </el-col>
        <!-- 旅行枕头 -->
        <el-col :span="12">
          <el-card class="gift_card" @click.native="list[1]?.total == 0 ? false : select(2)"
            :class="{ active: selected == 2 }">
            <div class="img2">
              <img v-if="list[1]?.total == 0" src="../assets/images/Rectangle.png" alt="" />
              <span v-if="list[1]?.total == 0">{{ $t('fullyRedeem') }}</span>
            </div>
            <label for="" class="title">{{ $t('card') }}</label>
            <label for="" class="count">{{ $t('avaliable') }}: {{ list[1]?.total }}</label>
          </el-card>
        </el-col>
        <!-- 橙汁 -->
        <el-col :span="12">
          <el-card class="gift_card" @click.native="list[2]?.total == 0 ? false : select(3)"
            :class="{ active: selected == 3 }">
            <div class="img3">
              <img v-if="list[2]?.total == 0" src="../assets/images/Rectangle.png" alt="" />
              <span v-if="list[2]?.total == 0">{{ $t('fullyRedeem') }}</span>
            </div>
            <label for="" class="title">{{ $t('orangeJuice') }}</label>
            <label for="" class="count">{{ $t('avaliable') }}: {{ list[2]?.total }}</label>
          </el-card>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-button class="btn" @click="handleNext()">{{ $t('next') }}</el-button>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import { giftList, userGift } from '@/api/register';
import GiftPage from "../views/giftPage.vue";
import storage from '@/utils/storage';
import i18n from '@/lang/language';

export default {
  name: GiftPage,
  data() {
    return {
      selected: 0,
      list: [],
      language: 'en',
      info: {}
    };
  },
  created() {
  },
  mounted() {
    if (storage.get('language') != null) {
      i18n.locale = storage.get('language');
      this.language = storage.get('language');
    } else {
      i18n.locale = 'en';
      storage.set('language', 'en');
      this.language = storage.get('language');
    }
    this.getGiftList();
    this.info = storage.get('userInfo');
    console.log(this.info);
  },
  methods: {
    chooseChinese() {
      i18n.locale = 'cn';
      storage.set('language', 'cn');
      this.language = storage.get('language');
    },
    chooseEnglish() {
      i18n.locale = 'en';
      storage.set('language', 'en');
      this.language = storage.get('language');
    },
    getGiftList() {
      giftList().then(res => {
        console.log(res);
        this.list = res.data;
      })
    },
    select(num) {
      this.selected = num;
      console.log(num);
    },
    handleNext() {
      if (this.info == null) {
        this.$alert("参数为空", this.$t('notice'), {
          confirmButtonText: this.$t('confirm'),
          customClass: "noticeBox",
          center: true,
          showClose: false
        }).then(() => {
          this.$router.push({
            path: '/signupPage',
          });
        });
      } else if (this.selected != 0) {
        userGift({
          userPhone: "+65 " + this.info.phone,
          code: this.info.code,
          userPassword: this.info.password,
          otherInvitationCode: this.info?.referralCode,
          accessType: 3,
          giftId: this.selected
        }).then((res) => {
          console.log(res);
          if (res.code == 0) {
            storage.set('userStatus', 0);
            this.$router.push({
              path: '/resultPage',
              // query: {
              //   userStatus: 0,
              // },
            });
          }else if (res.code == 16) {
            this.$alert(this.$t('timeout'), this.$t('notice'), {
              confirmButtonText: this.$t('confirm'),
              customClass: "noticeBox",
              center: true,
              showClose: false
            }).then(() => {
              this.$router.push({
                path: '/signupPage',
              });
            });
          } else if (res.code == 100) {
            this.$alert(res.msg, this.$t('notice'), {
              confirmButtonText: this.$t('confirm'),
              customClass: "noticeBox",
              center: true,
              showClose: false
            }).then(() => {
              this.$router.push({
                path: '/signupPage',
              });
            });
          } else {
            this.$alert(res.msg, this.$t('notice'), {
              confirmButtonText: this.$t('confirm'),
              customClass: "noticeBox",
              center: true,
              showClose: false
            }).then(() => {
              storage.set('userStatus', 0);
              this.$router.push({
                path: '/resultPage',
                // query: {
                //   userStatus: 0,
                // },
              });
            });
          }
        }).catch(err => {
          console.log(err);
        });
      }
    }
  },
};
</script>

<style>
.el-message-box.noticeBox {
  width: 80%;
}
</style>

<style lang="less" scoped>
@media screen and (max-width: 766px) {
  .el-header {
    position: relative;
    top: 0;
    left: 0;

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200px;
      height: 40px;
      line-height: 40px;
      margin-left: -100px;
      margin-top: -20px;
      font-size: 20px;
      font-weight: 500px;
      text-align: center;
    }

    .lang_div {
      position: absolute;
      top: 50%;
      right: 5%;
      height: 40px;
      margin-top: -20px;

      span {
        display: inline-block;
        height: 40px;
        line-height: 40px;
      }

      .cn {
        color: #868686;

        &.active {
          color: #7723b6;
        }
      }

      .en {
        color: #868686;

        &.active {
          color: #7723b6;
        }
      }
    }
  }

  .list {
    padding: 0;

    .gift_card {
      width: 80%;
      margin: 5px auto;
      border: 2px solid transparent;

      .img1 {
        position: relative;
        width: 100px;
        height: 100px;
        margin: 0 auto;
        border-radius: 10px;
        background: url(../assets/images/ball.png) no-repeat;
        background-size: contain;

        img {
          width: 100%;
        }

        span {
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 24px;
          margin-top: -12px;
          color: #fff;
          text-align: center;
        }
      }

      .img2 {
        position: relative;
        width: 100px;
        height: 100px;
        margin: 0 auto;
        border-radius: 10px;
        // background: url(../assets/images/pillow.png) no-repeat;
        background: url(../assets/images/cardHold.png) no-repeat;
        background-size: contain;

        img {
          width: 100%;
        }

        span {
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 24px;
          margin-top: -12px;
          color: #fff;
          text-align: center;
        }
      }

      .img3 {
        position: relative;
        width: 100px;
        height: 100px;
        margin: 0 auto;
        border-radius: 10px;
        background: url(../assets/images/orange.png) no-repeat;
        background-size: contain;

        img {
          width: 100%;
        }

        span {
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 24px;
          margin-top: -12px;
          color: #fff;
          text-align: center;
        }
      }


      label {
        display: block;
        width: 100%;
        margin: 5px auto;
        text-align: center;
      }

      .title {
        font-size: 16px;
        // height: 19px;
        // line-height: 19px;
      }

      .count {
        color: rgb(15, 93, 170);
      }

      &.active {
        box-shadow: 0 2px 6px rgba(255, 255, 255, 0.932);
        // box-shadow: 0 0 10px 5px rgb(148, 15, 160);
        border-color: rgb(148, 15, 160);
        border-width: 2px;
        transition: all 0.2s ease-in-out;
      }
    }

    .btn {
      display: block;
      width: 90%;
      height: 40px;
      margin: 10px auto;
      color: #fff;
      border: none;
      background: url("../assets/images/btn01.png") no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
}
</style>
