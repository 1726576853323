export default {
  countryCodeNotNull: "Country code cannot be empty",
  phoneNumber: "Please input phone number",
  phoneNumberNotNull: "Phone Number cannot be empty",
  verificationCode: "Enter Verification Code",
  verificationCodeNotNull: "Verification Code cannot be empty",
  send: "Send",
  password: "Password",
  passwordNotNull: "Password cannot be empty",
  referralCode: "Referral Code(Optional)",
  register: "Sign Up",
  registered: "Already Registered?",
  download: "Download App Instead.",
  click: "By clicking Sign Up you have agreed to our ",
  service: "Terms of Service", 
  and: "and",
  policy: "Privacy Policy",

  notice: "Notice",
  alreadyRegistered: "Your phone number is already registered!",
  confirm: "Ok",

  chooseReward: "Choose your reward",
  surpriseBox: "Surprise Box",
  pillow: "Travel Pillow",
  orangeJuice: "Ijooz Orange Juice",
  card: "Anti-skin Card Protector",
  avaliable: "Available",
  fullyRedeem: "fully redeem",
  next: "NEXT",
  timeout: "Session timeout, please try again.",

  signupSuccessful: "Sign Up Successful",
  alreadySignup: "Already Sign Up",
  redeemReward: "How to redeem your reward?",
  step1: "Step 1",
  step1Content: "Download our App and complete the following steps",
  step2: "Step 2",
  step2Content: "Press the [Rewards] after login;",
  step3: "Step 3",
  step3Content: "Complete a very short survey;",
  step4: "Step 4",
  step4Content: "Look for your reward redemption code and show it to our friendly staff.",
  down: "Download APP",
};
