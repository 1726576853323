import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import i18n from './lang/language'
import storage from './utils/storage'
import VuCountryIntl from 'vue-country-intl';
import 'vue-country-intl/lib/vue-country-intl.css';

Vue.component(VuCountryIntl.name, VuCountryIntl);

Vue.config.productionTip = false

Vue.use(ElementUI,{
  size: 'medium',
  i18n:(key,value) => i18n.t(key,value)
})

new Vue({
  router,
  i18n,
  storage,
  render: h => h(App),
}).$mount('#app')
