<template>
  <div class="resDiv">
    <div class="header_div">
      <img class="logo" src="../assets/images/logo.png" alt="">
      <!-- <div class="lang_div">
        <span class="cn" :class="{ active: language == 'cn' }" @click="chooseChinese()">中文</span>
        | <span class="en" :class="{ active: language == 'en' }" @click="chooseEnglish()">EN</span>
      </div> -->
    </div>
    <div class="banner">
      <img v-if="status == 0 || status == 1 || status == 2" src="../assets/images/BannerCompleted.jpg" alt="" class="banner_img" />
      <img v-if="status == 3" src="../assets/images/WelcomeBack.jpg" alt="" class="banner_img" />
      <!-- <p v-if="status == 0">{{ $t('signupSuccessful') }}</p> -->
      <!-- <p v-if="status == 1">{{ $t('alreadySignup') }}</p> -->
    </div>
    <!-- result -->
    <el-main>
      <el-card class="res_card">
        <el-row>
          <el-col class="res_logo">
            <img src="../assets/images/mars.png">
          </el-col>
          <el-col class="res_title">
            <h3>MarsConnect</h3>
          </el-col>
          <el-col class="res_tips">
            <p>New Generation Independent <br> Wealth Portfolio Management App</p>
          </el-col>
          <!-- <el-col :offset="5" :span="6" class="res_down">
            <img src="../assets/images/appleBtn.png" alt="">
          </el-col>
          <el-col :offset="2" :span="6" class="res_down">
            <img src="../assets/images/googleBtn.png" alt="">
          </el-col> -->
          <el-col class="res_new" v-if="status == 1">
            <p>
              Welcome to MarsConnect
            </p>
            <!-- <p>
              Download now & redeem <br> your <strong class="yellowTx"> S$5 NTUC E-Voucher</strong>
            </p> -->
          </el-col>
          <el-col class="res_not" v-if="status == 2">
            <p>
              MarsConnect is currently only <br> available for Singapore residence. <br><br> We look forward to serving you in the future.
            </p>
          </el-col>
          <el-col class="res_register" v-if="status == 3">
            <p>
              Welcome Back! <br> Start your planning today!
            </p>
          </el-col>
          <el-col>
            <a v-if="status == 1" href="https://marsconnect.app.link/xXcLpt6a1Db" class="next_a" @click="handleDownload()">Redeem Now</a>
            <a v-if="status == 2" href="https://www.marsconnect.io/" class="next_a">Visit Website</a>
            <a v-if="status == 3" href="https://marsconnect.app.link/xXcLpt6a1Db" class="next_a" @click="handleNext()">Download Now</a>
          </el-col>
        </el-row>
      </el-card>
    </el-main>
  </div>
</template>

<script>
import ResultPage from "../views/resultPage.vue";
import storage from "@/utils/storage";
import i18n from "@/lang/language";

export default {
  name: ResultPage,
  data() {
    return {
      status: 0,
      step2: true,
      step2Content: true,
      step3: false,
      step3Content: false,
      step4: false,
      step4Content: false,
      order: 2,
      endStatus: 0,
      language: 'en'
    };
  },
  created() {
    // this.status = this.$route.query.userStatus;
    this.status = storage.get('userStatus');
    console.log(this.status);
  },
  mounted() {
    if (storage.get('language') != null) {
      i18n.locale = storage.get('language');
      this.language = storage.get('language');
    } else {
      i18n.locale = 'en';
      storage.set('language', 'en');
      this.language = storage.get('language');
    }
  },
  methods: {
    chooseChinese() {
      i18n.locale = 'cn';
      storage.set('language', 'cn');
      this.language = storage.get('language');
    },
    chooseEnglish() {
      i18n.locale = 'en';
      storage.set('language', 'en');
      this.language = storage.get('language');
    },
    handleStep(num) {
      switch (num) {
        case 2:
          this.step2Content = !this.step2Content;
          this.endStatus = 0;
          break;
        case 3:
          this.step3Content = !this.step3Content;
          this.endStatus = 0;
          break;
        case 4:
          this.step4Content = !this.step4Content;
          this.endStatus = 1;
          break;
      }
      this.order = num;
    },
    handleNext() {
      switch (this.order) {
        case 1: {
          this.step2 = true;
          this.step3 = false;
          this.step4 = false;
          this.order = 2;
          break;
        }
        case 2: {
          this.step2 = true;
          this.step2Content = false;
          this.step3 = true;
          this.step3Content = true;
          this.step4 = false;
          this.order = 3;
          break;
        }
        case 3: {
          this.step2 = true;
          this.step2Content = false;
          this.step3 = true;
          this.step3Content = false;
          this.step4 = true;
          this.step4Content = true;
          this.order = 4;
          this.endStatus = 1;
          break;
        }
        case 4: {
          this.endStatus = 1;
          break;
        }
      }
    },
    // 下载点击事件
    handleDownload() {
      // 判断机型 android或ios
      let ua = navigator.userAgent;

      let isAndroid = ua.indexOf("Android") > -1 || ua.indexOf("Linux") > -1;
      let isIOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

      if (isAndroid) {
        // alert("暂未上架，敬请期待");
        window.location.href =
          "https://play.google.com/store/apps/details?id=io.marsconnect.client&hl=zh&gl=us"; // 谷歌应用商店
      }
      if (isIOS) {
        window.location.href =
          "https://apps.apple.com/us/app/marsconnect-financial/id1643648625"; //下载链接 跳转到苹果应用商店
      }
    },
  }
}
</script>

<style lang="less" scoped>
.resDiv {
  width: 400px;
  margin: 0 auto;
  background-color: #fbfbfd;

  .header_div {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);

    .logo {
      position: absolute;
      top: 50%;
      left: 5%;
      height: 40px;
      margin-top: -20px;
    }

    .lang_div {
      position: absolute;
      top: 50%;
      right: 5%;
      height: 30px;
      margin-top: -15px;

      span {
        display: inline-block;
        height: 30px;
        line-height: 30px;
      }

      .cn {
        color: #868686;

        &.active {
          color: #7723B6;
        }
      }

      .en {
        color: #868686;

        &.active {
          color: #7723B6;
        }
      }
    }
  }

  .banner {
    position: relative;
    top: 15px;
    left: 0;
    width: 100%;
    height: 130px;

    .banner_img {
      width: 100%;
    }

    p {
      position: absolute;
      top: 50%;
      right: 20%;
      width: 90px;
      height: 42px;
      margin-top: -25px;
      color: #7723B6;
      font-size: 20px;
      font-weight: medium;
    }
  }

  .el-main {
    padding: 15px 0;
    width: 100%;

    .res_card {
      width: 90%;
      margin: 10px auto;
      border-radius: 40px;

      .el-card__body {
        padding: 20px 15px;
      }

      .res_logo {
        position: relative;
        height: 125px;

        img {
          position: absolute;
          left: 50%;
          width: 120px;
          margin-left: -60px;
        }
      }

      .res_title {
        h3 {
          margin: 10px 0 0;
          color: #7723B6;
          text-align: center;
        }
      }

      .res_tips {
        p {
          width: 100%;
          margin: 0 auto;
          font-size: 14px;
          text-align: center;
        }
      }
      .res_down {
        margin-top: 10px;
        img {
          width: 90px;
        }
      }

      .res_register {
        p {
          color: #7723B6;
          font-size: 24px;
          font-weight: 700;
          text-align: center;
        }
      }

      .res_new {
        p {
          color: #7723B6;
          font-size: 24px;
          font-weight: 700;
          text-align: center;

          strong {
            color: #FF7A00;
          }
        }
      } 

      .res_not {
        p {
          color: #7723B6;
          font-size: 14px;
          font-weight: 600;
          text-align: center;
        }
      }

      .next_a {
        display: block;
        width: fit-content;
        line-height: 28px;
        padding: 15px 30px;
        margin: 10px auto 0;
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        text-decoration: none;
        cursor: pointer;
        border-radius: 29px;
        background-color: #7723B6;
      }
    }
  }
}

@media screen and (max-width: 766px) {
  .resDiv {
    width: 100%;

    .header_div {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 60px;
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);

      .logo {
        position: absolute;
        top: 50%;
        left: 5%;
        height: 40px;
        margin-top: -20px;
      }

      .lang_div {
        position: absolute;
        top: 50%;
        right: 5%;
        height: 30px;
        margin-top: -15px;

        span {
          display: inline-block;
          height: 30px;
          line-height: 30px;
        }

        .cn {
          color: #868686;

          &.active {
            color: #7723B6;
          }
        }

        .en {
          color: #868686;

          &.active {
            color: #7723B6;
          }
        }
      }
    }

    .banner {
      position: relative;
      top: 15px;
      left: 0;
      width: 100%;
      height: 130px;

      .banner_img {
        width: 100%;
      }

      p {
        position: absolute;
        top: 50%;
        right: 20%;
        width: 90px;
        height: 42px;
        margin-top: -25px;
        color: #7723B6;
        font-size: 20px;
        font-weight: medium;
      }
    }

    .el-main {
      padding: 0;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 75%;
      overflow: scroll;

      .res_card {
        width: 90%;
        margin: 10px auto;
        border-radius: 40px;

        .res_register p,
        .res_new p {
          font-size: 22px;
        }

        .next_btn {
          display: block;
          padding: 15px 30px;
          margin: 10px auto 0;
          color: #fff;
          font-size: 24px;
          font-weight: 700;
          border: none;
          border-radius: 29px;
          background-color: #7723B6;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .resDiv {
    .el-main {
      height: 70%;
    }
  }
}

@media screen and (max-width: 393px) {
  .resDiv {
    .el-main {
      height: 75%;
    }
  }
}

@media screen and (max-width: 375px) {
  .resDiv {
    .el-main {
      height: 70%;
    }
  }
}

@media screen and (max-width: 360px) {
  .resDiv {
    .el-main {
      height: 73%;
    }
  }
}
</style>
