import Vue from "vue";
import VueI18n from "vue-i18n";
import elementEnLocale from "element-ui/lib/locale/lang/en"; // element-ui lang
import elementZhLocale from "element-ui/lib/locale/lang/zh-CN"; // element-ui lang
import enLocale from "./en";
import cnLocale from "./cn";
import storage from "../utils/storage";

Vue.use(VueI18n);

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale,
  },
  cn: {
    ...cnLocale,
    ...elementZhLocale,
  },
};

export function getLanguage() {
  const chooseLanguage = storage.get("language");
  console.log(chooseLanguage);
  if (chooseLanguage === "cn") {
    return "cn";
  } else if (chooseLanguage === "en") {
    return "en";
  } else {
    return "en";
  }

  //   const chooseLanguage = storage.get("language") || "cn";
  //   if (chooseLanguage) return chooseLanguage

  // if has not choose language
  //   const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  //   const locales = Object.keys(messages)
  //   for (const locale of locales) {
  //     if (language.indexOf(locale) > -1) {
  //       return locale
  //     }
  //   }
  //   return 'en'
}

const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  // locale: getLanguage(),
  locale: "en",
  // set locale messages
  messages,
});

export default i18n;
