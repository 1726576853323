export default {
  countryCodeNotNull: "国家码不能为空",
  phoneNumber: "请输入手机号",
  phoneNumberNotNull: "手机号不能为空",
  verificationCode: "验证码",
  verificationCodeNotNull: "验证码不能为空",
  send: "发送",
  password: "密码",
  passwordNotNull: "密码不能为空",
  referralCode: "推荐码（选填）",
  register: "注册",
  registered: "已经注册了",
  download: "直接下载APP",
  click: "点击注册代表同意",
  service: "服务条款",
  and: "和",
  policy: "隐私协议",

  notice: "提示",
  alreadyRegistered: "你的手机号已注册！",
  confirm: "确定",

  chooseReward: "选择你的礼品",
  surpriseBox: "盲盒",
  pillow: "旅行围脖",
  orangeJuice: "Ijooz 橙汁",
  card: "防盗卡包",
  avaliable: "剩余",
  fullyRedeem: "售罄",
  next: "下一步",
  timeout: "操作超时",

  signupSuccessful: "注册成功",
  alreadySignup: "已注册",
  redeemReward: "如何领取礼物？",
  step1: "第一步",
  step1Content: "下载APP并完成接下来的步骤",
  step2: "第二步",
  step2Content: "登录，点击奖励按钮；",
  step3: "第三步",
  step3Content: "按弹框提示完成调查问卷；",
  step4: "第四步",
  step4Content: "领取礼品兑换二维码，并向工作人员出示。",
  down: "下载APP",
};
