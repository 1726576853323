<template>
  <div class="signup">
    <div class="header_div">
      <img class="logo" src="../assets/images/logo.png" alt="">
      <!-- 切换语言 -->
      <!-- <div class="lang_div">
        <span class="cn" :class="{ active: language == 'cn' }" @click="chooseChinese()">中文</span>
        | <span class="en" :class="{ active: language == 'en' }" @click="chooseEnglish()">EN</span>
      </div> -->
    </div>
    <div class="banner">
      <img src="../assets/images/MainPage.jpg" alt="" class="banner_img" />
    </div>
    <!-- info :rules="language == 'en' ? infoRulesEn : infoRulesCn"-->
    <el-main>
      <el-card class="info_card">
        <el-form ref="infoForm" :model="infoForm" :rules="infoRules">
          <el-form-item prop="countryCode" class="countryCode">
            <VueCountryIntl ref="countryIntl" :iso2="'sg'" schema="input" :showSelectedText="false" :iosMobileReadonly="false" class="countryCode_select" v-model="countryCode" :transform="transform" placeholder="Please select country"></VueCountryIntl><br>
          </el-form-item>
          <el-form-item prop="phone">
            <el-input type="tel" class="phone_input" pattern="[0-9]*" v-model="infoForm.phone" :placeholder="$t('phoneNumber')"
              onkeypress='return( /[\d]/.test(String.fromCharCode(event.keyCode)))'  
              oninput="value=value.replace(/[^\d]/g,'')" required>
            </el-input>
          </el-form-item>
          <!-- <el-form-item prop="code">
            <el-input type="tel" class="code_input" pattern="[0-9]*" v-model="infoForm.code" minlength="6" maxlength="6"
              :placeholder="$t('verificationCode')" required
              onkeypress='return( /[\d]/.test(String.fromCharCode(event.keyCode)))'  
              oninput="value=value.replace(/[^\d]/g,'')">
              <el-button class="send_btn" slot="append" @click="sendCode()" :disabled="sendStatus">
                {{ sendTxt }}
              </el-button>
            </el-input>
          </el-form-item> -->
          <!-- <el-form-item prop="password">
            <el-input type="password" :placeholder="$t('password')" v-model="infoForm.password" required>
            </el-input>
          </el-form-item> -->
          <!-- <el-form-item>
            <el-input :placeholder="$t('referralCode')" v-model="infoForm.referralCode">
            </el-input>
          </el-form-item> -->
        </el-form>
        <el-button class="signup_btn" @click="handleSignup()">
          {{ $t('register') }}
        </el-button>
        <p class="signin">
          {{ $t('registered') }} <a :class="{ active: clicked == true }" @click="handleDownload()">{{ $t('download') }}</a>
        </p>
        <p class="service">
          {{ $t('click') }}
          <a href="https://www.marsconnect.io/terms-of-service/">
            {{ $t('service') }}
          </a>
          {{ $t('and') }}
          <a href="https://www.marsconnect.io/privacy-policy/">
            {{ $t('policy') }}
          </a>
        </p>
      </el-card>
    </el-main>
  </div>
</template>

<script>
import SignupPage from "../views/signupPage.vue";
import { registeredUser, sendVerificationCode } from "../api/register";
import storage from '@/utils/storage';
import i18n from '@/lang/language';
import _ from 'lodash';

export default {
  name: SignupPage,
  data() {
    // 手机号
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.phoneNumberNotNull));
      } else {
        callback();
      }
    }
    // 验证码
    // const validateCode = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error(this.verificationCodeNotNull));
    //   } else {
    //     callback();
    //   }
    // }

    // const validateCountryCode = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error(this.countryCodeNotNull));
    //   } else {
    //     callback();
    //   }
    // }
    return {
      options: [
        {
          key: 0,
          value: "China(+86)",
        },
        {
          key: 1,
          value: "Singapore(+65)",
        },
      ],
      infoForm: {
        phone: "",
        code: "",
        password: "",
        referralCode: "",
      },
      countryCode: "65",
      phoneNumber: "",
      countryCodeNotNull: "",
      phoneNumberNotNull: "",
      verificationCodeNotNull: "",
      passwordNotNull: "",
      sendStatus: false,
      sendTxt: '',
      totalSeconds: 60,
      countDown: "",
      clicked: false,
      phoneTips: 0,
      signupTips: 0,
      language: 'en',
      infoRules: {
        // countryCode: [
        //   // 国家码不能为空
        //   {
        //     required: true,
        //     trigger: "blur",
        //     validator: validateCountryCode
        //   },
        // ],
        phone: [
          // 手机号不能为空
          {
            required: true,
            trigger: "blur",
            validator: validatePhone
          },
        ],
        // code: [
        //   // 验证码不能为空
        //   {
        //     required: true,
        //     trigger: "blur",
        //     validator: validateCode
        //   },
        // ]
      },
    };
  },
  mounted() {
    this.countryCodeNotNull = this.$t('countryCodeNotNull');
    this.phoneNumberNotNull = this.$t('phoneNumberNotNull');
    this.verificationCodeNotNull = this.$t('verificationCodeNotNull');
    this.passwordNotNull = this.$t('passwordNotNull');
    this.sendTxt = this.$t('send');
    this.$refs.countryIntl.$refs.schemaInput._onSelectedChange({
      "name": "Singapore",
      "nameCN": "新加坡",
      "iso2": "sg",
      "dialCode": "65",
      "priority": 0,
      "areaCodes": null
    })
  },
  methods: {
    transform (countrys) {
      console.log(countrys);
      // 示例用法
      let elementToTop = 'sg';
      let elementToTwo = 'vn';
      let sortedArray = this.customSort(countrys, elementToTop, elementToTwo);
      console.log(sortedArray[0])
      return sortedArray;
    },
    customSort(arr, elementToTop, elementToTwo) {
      // 比较函数
      function compareFunction(a, b) {
          if (a.iso2 === elementToTop) {
              return -1; // a 在 b 之前
          } else if (b.iso2 === elementToTop) {
              return 1; // b 在 a 之前
          } else if (a.iso2 === elementToTwo) {
            return -1;
          } else if (b.iso2 === elementToTwo) {
            return 1;
          }
      }
      // 使用比较函数进行排序
      arr.sort(compareFunction);
      return arr;
    },
    chooseChinese() {
      i18n.locale = 'cn';
      storage.set('language', 'cn');
      this.language = storage.get('language');
      this.phoneNumberNotNull = this.$t('phoneNumberNotNull');
      this.verificationCodeNotNull = this.$t('verificationCodeNotNull');
      this.passwordNotNull = this.$t('passwordNotNull');
      this.sendTxt = this.$t('send');
    },
    chooseEnglish() {
      i18n.locale = 'en';
      storage.set('language', 'en');
      this.language = storage.get('language');
      this.phoneNumberNotNull = this.$t('phoneNumberNotNull');
      this.verificationCodeNotNull = this.$t('verificationCodeNotNull');
      this.passwordNotNull = this.$t('passwordNotNull');
      this.sendTxt = this.$t('send');
    },
    // 按钮防抖
    sendCode: _.debounce(function () {
      this.handleSend();
    }, 500),
    // 发送验证码
    handleSend() {
      let that = this;
      if (that.countryCode == '') {
        that.$alert('Please select at least one country code', that.$t('notice'), {
          confirmButtonText: that.$t('confirm'),
          customClass: "noticeBox",
          center: true,
          showClose: false
        });
        return;
      } 
      if(that.countryCode == '65' && that.infoForm.phone.search(/^[8|9]\d{7}$/) == -1) {
        that.$alert('Please enter a valid mobile number.', that.$t('notice'), {
          confirmButtonText: that.$t('confirm'),
          customClass: "noticeBox",
          center: true,
          showClose: false
        });
        return;
      } 
      if(that.countryCode == '86' && that.infoForm.phone.search(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/) == -1) {
        that.$alert('Please enter a valid mobile number.', that.$t('notice'), {
          confirmButtonText: that.$t('confirm'),
          customClass: "noticeBox",
          center: true,
          showClose: false
        });
        return;
      }
      that.phoneNumber = "+" + that.countryCode + ' ' + that.infoForm.phone;
      if (that.infoForm.phone !== "") {
        that.sendStatus = true;
        sendVerificationCode({
          phoneNumber: that.phoneNumber,
        })
          .then((res) => {
            console.log(res);
            that.phoneTips = res.code;
            if (that.phoneTips === 36) { // 手机号已注册
              that.$alert(
                res.msg,
                that.$t('notice'),
                {
                  confirmButtonText: that.$t('confirm'),
                  customClass: "noticeBox",
                  center: true,
                  showClose: false
                }
              ).then(() => {
                storage.set('userStatus', 3);
                that.$router.push({
                  path: "/resultPage",
                });
              });
            } else if (that.phoneTips === 0) {
              that.totalSeconds = 60;
              that.countDown = setInterval(() => {
                if (that.totalSeconds <= 1) {
                  that.sendStatus = false;
                  that.sendTxt = that.$t('send');
                  if (that.countDown) {
                    clearInterval(that.countDown);
                  }
                  return;
                } else {
                  that.totalSeconds--;
                  that.sendTxt = that.totalSeconds + "s";
                }
              }, 1000);
            } else { //if (that.phoneTips === 23) Please enter a valid mobile number.
              that.$alert(res.msg, that.$t('notice'), {
                confirmButtonText: that.$t('confirm'),
                customClass: "noticeBox",
                center: true,
                showClose: false
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // 注册点击事件
    handleSignup() {
      let that = this;
      if (that.countryCode == '') {
        that.$alert('Please select at least one country code', that.$t('notice'), {
          confirmButtonText: that.$t('confirm'),
          customClass: "noticeBox",
          center: true,
          showClose: false
        });
        return;
      } 
      if(that.infoForm.phone.length < 6 || that.infoForm.phone.length > 15) {
        that.$alert('Please enter a valid mobile number.', that.$t('notice'), {
          confirmButtonText: that.$t('confirm'),
          customClass: "noticeBox",
          center: true,
          showClose: false
        });
        return;
      }
      if(that.countryCode == '65' && that.infoForm.phone.search(/^[8|9]\d{7}$/) == -1) {
        that.$alert('Please enter a valid mobile number.', that.$t('notice'), {
          confirmButtonText: that.$t('confirm'),
          customClass: "noticeBox",
          center: true,
          showClose: false
        });
        return;
      } 
      if(that.countryCode == '84' && ('+' + that.countryCode + that.infoForm.phone).search(/^\+84\d{9}$/) == -1) {
        that.$alert('Please enter a valid mobile number.', that.$t('notice'), {
          confirmButtonText: that.$t('confirm'),
          customClass: "noticeBox",
          center: true,
          showClose: false
        });
        return;
      } 
      if(that.countryCode == '86' && that.infoForm.phone.search(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/) == -1) {
        that.$alert('Please enter a valid mobile number.', that.$t('notice'), {
          confirmButtonText: that.$t('confirm'),
          customClass: "noticeBox",
          center: true,
          showClose: false
        });
        return;
      }
      that.phoneNumber = "+" + that.countryCode + " " + that.infoForm.phone;
      that.$refs["infoForm"].validate((valid) => {
        if (valid) {
          registeredUser({
            userPhone: that.phoneNumber,
            // phoneCode: that.infoForm.code,
          })
            .then((res) => {
              console.log(res);
              that.signupTips = res.code;
              storage.set('userInfo', that.infoForm);
              if (that.signupTips === 0) {
                if (res.data === 1) { 
                  storage.set('userStatus', 1); // 送优惠券
                } else if(res.data === 2) { 
                  storage.set('userStatus', 2); // 首次注册 非+65
                } else { 
                  storage.set('userStatus', 3); // 非首次注册 成功
                }
                that.resetInfoForm();
                that.$router.push({
                  path: "/resultPage",
                });
              } else if (that.signupTips === 16) {
                // 验证码错误 Invalid verification code, try again. 
                that.$alert(res.msg, that.$t('notice'), {
                  confirmButtonText: that.$t('confirm'),
                  customClass: "noticeBox",
                  center: true,
                  showClose: false
                });
              } else if(that.signupTips === 19) { 
                // 非首次注册 成功 Account already exists.
                that.$alert(
                  res.msg,
                  that.$t('notice'),
                  {
                    confirmButtonText: that.$t('confirm'),
                    customClass: "noticeBox",
                    center: true,
                    showClose: false
                  }
                ).then(() => {
                  storage.set('userStatus', 3);
                  that.$router.push({
                    path: "/resultPage",
                  });
                });
              } else if (that.signupTips === 21) {
                //邀请码错误 Invalid referral code.
                that.$alert(res.msg, that.$t('notice'), {
                  confirmButtonText: that.$t('confirm'),
                  customClass: "noticeBox",
                  center: true,
                  showClose: false
                });
              } else {
                that.$alert(res.msg, that.$t('notice'), {
                  confirmButtonText: that.$t('confirm'),
                  customClass: "noticeBox",
                  center: true,
                  showClose: false
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    // 下载点击事件
    handleDownload() {
      this.clicked = true;

      // 判断机型 android或ios
      let ua = navigator.userAgent;

      let isAndroid = ua.indexOf("Android") > -1 || ua.indexOf("Linux") > -1;
      let isIOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

      if (isAndroid) {
        // alert("暂未上架，敬请期待");
        window.location.href =
          "https://play.google.com/store/apps/details?id=io.marsconnect.client&hl=zh&gl=us"; // 谷歌应用商店
      }
      if (isIOS) {
        window.location.href =
          "https://apps.apple.com/us/app/marsconnect-financial/id1643648625"; //下载链接 跳转到苹果应用商店
      }
    },
    resetInfoForm() {
      this.infoForm = {
        phone: "",
        code: "",
        password: "",
        referralCode: "",
      };
    },
    // 处理页面跳转
    handlePage(path) {
      if (this.$route.path === path) {
        return false;
      }
      this.$router.push({
        path,
      });
    },
  },
};
</script>

<style>
* {
  cursor: default;
}

.el-message-box.noticeBox {
  width: 300px !important;
}

.countryCode .el-form-item__content {
  height: 42px !important;
}

.vue-country-intl .country-intl-input {
  width: auto !important;
  padding: 0 55px 0 15px !important;
}

.vue-country-intl .vue-country-list-wrap {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.vue-country-intl .vue-country-list-wrap .vue-country-list {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.vue-country-intl .vue-country-list-wrap .vue-country-list .vue-country-item {
  width: 80%;
}

.vue-country-intl .vue-country-list-wrap .vue-country-list .vue-country-item .vue-country-name {
  display: inline-block;
  width: 60%;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.vue-country-intl .country-intl-input::placeholder {
  color: #cbc4cc !important;
}
.vue-country-intl .country-intl-input::-webkit-input-placeholder {
  color: #cbc4cc !important;
}
.vue-country-intl .country-intl-input::-ms-input-placeholder {
  color: #cbc4cc !important;
}

.vue-country-intl .country-intl-label {
  width: 80% !important;
  padding: 0 15px !important;
}

@media screen and (max-width: 766px) {
  .el-message-box.noticeBox {
    width: 80% !important;
  }
}
</style>

<style lang="less" scoped>
.signup {
  width: 400px;
  margin: 0 auto;
  background-color: #fbfbfd;

  .header_div {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background-color: #fff;

    .logo {
      position: absolute;
      top: 50%;
      left: 5%;
      height: 40px;
      margin-top: -20px;
    }

    .lang_div {
      position: absolute;
      top: 50%;
      right: 5%;
      height: 30px;
      margin-top: -15px;

      span {
        display: inline-block;
        height: 30px;
        line-height: 30px;
      }

      .cn {
        color: #868686;

        &.active {
          color: #7723b6;
        }
      }

      .en {
        color: #868686;

        &.active {
          color: #7723b6;
        }
      }
    }
  }

  .banner {
    position: relative;
    top: 15px;
    left: 0;
    width: 100%;
    height: 215px;

    .banner_img {
      display: block;
      width: 100%;
    }
  }

  .el-main {
    padding: 10px 0;
    width: 100%;

    .info_card {
      width: 90%;
      margin: 10px auto;
      border-radius: 10px;

      .phone_input {
        width: 100%;
      }

      .code_input {
        width: 100%;

        .send_btn {
          width: 70px;
        }
      }
    }

    .el-radio {
      text-overflow: ellipsis;
      white-space: normal;
      line-height: 18px;
      vertical-align: middle;
      display: inline-block;

      margin: 0 auto;

      a {
        text-decoration: none;
      }
    }

    .signup_btn {
      display: block;
      width: 100%;
      height: 40px;
      margin-top: 10px;
      color: #fff;
      font-size: 16px;
      border: none;
      background: url(../assets/images/btn01.png) no-repeat;
      background-size: 100% 100%;
    }

    .signin {
      font-size: 16px;
      text-align: center;

      a {
        color: #7723b6;
        text-decoration: none;

        // &.active {
        //   color: #7723b6;
        // }
      }
    }

    .service {
      font-size: 16px;
      text-align: center;

      a {
        color: #7723b6;
        text-decoration: none;
      }
    }

    .bottom_img {
      width: 50%;
      margin: 0 25%;
    }

    .res_card {
      width: 90%;
      margin: 10px auto;
      border-radius: 40px;

      .res_tips {
        width: 90%;
        margin: 0 auto;
        font-size: 20px;
        font-weight: 500px;
        text-align: center;
      }

      .down_tips {
        width: 90%;
        margin: 10px auto 0;
        color: #8d8d8f;
        font-size: 16px;
        text-align: center;
      }

      .voucher_img {
        width: 100%;
        margin: 10px auto;
      }

      .code_div {
        float: left;
        width: 50%;
        margin: 10px auto;

        .qrcode_img {
          display: block;
          width: 80%;
          margin: 0 auto;
        }
      }

      .down_btn {
        display: block;
        float: left;
        width: 50%;
        margin: 10px auto;

        .appstore_img {
          display: block;
          width: 80%;
          margin: 20px auto 10px;
        }

        .googeplay_img {
          display: block;
          width: 80%;
          margin: 0 auto;
        }
      }
    }
  }
}

@media screen and (max-width: 766px) {
  .signup {
    width: 100%;
    height: 100%;
    background: none;

    .header_div {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 60px;
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);

      .logo {
        position: absolute;
        top: 50%;
        left: 5%;
        height: 40px;
        margin-top: -20px;
      }

      .lang_div {
        position: absolute;
        top: 50%;
        right: 5%;
        height: 30px;
        margin-top: -15px;

        span {
          display: inline-block;
          height: 30px;
          line-height: 30px;
        }

        .cn {
          color: #868686;

          &.active {
            color: #7723b6;
          }
        }

        .en {
          color: #868686;

          &.active {
            color: #7723b6;
          }
        }
      }
    }

    .banner {
      position: relative;
      top: 15px;
      left: 0;
      width: 100%;
      height: 215px;

      .banner_img {
        display: block;
        width: 100%;
      }
    }

    .el-main {
      padding: 0;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 67%;
      overflow: scroll;

      .info_card {
        width: 90%;
        margin: 10px auto;
        border-radius: 10px;

        .phone_input {
          width: 100%;
        }

        .code_input {
          width: 100%;

          .send_btn {
            width: 70px;
          }
        }
      }

      .el-radio {
        text-overflow: ellipsis;
        white-space: normal;
        line-height: 18px;
        vertical-align: middle;
        display: inline-block;

        margin: 0 auto;

        a {
          text-decoration: none;
        }
      }

      .signup_btn {
        display: block;
        width: 100%;
        height: 40px;
        margin-top: 10px;
        color: #fff;
        font-size: 16px;
        border: none;
        background: url(../assets/images/btn01.png) no-repeat;
        background-size: 100% 100%;
      }

      .signin {
        font-size: 14px;
        text-align: center;

        a {
          color: #7723b6;
          text-decoration: none;
        }
      }

      .service {
        font-size: 14px;
        text-align: center;

        a {
          color: #7723b6;
          text-decoration: none;
        }
      }

      .bottom_img {
        width: 50%;
        margin: 0 25%;
      }

      .res_card {
        width: 90%;
        margin: 10px auto;
        border-radius: 40px;

        .res_tips {
          width: 90%;
          margin: 0 auto;
          font-size: 20px;
          font-weight: 500px;
          text-align: center;
        }

        .down_tips {
          width: 90%;
          margin: 10px auto 0;
          color: #8d8d8f;
          font-size: 16px;
          text-align: center;
        }

        .voucher_img {
          width: 100%;
          margin: 10px auto;
        }

        .code_div {
          float: left;
          width: 50%;
          margin: 10px auto;

          .qrcode_img {
            display: block;
            width: 80%;
            margin: 0 auto;
          }
        }

        .down_btn {
          display: block;
          float: left;
          width: 50%;
          margin: 10px auto;

          .appstore_img {
            display: block;
            width: 80%;
            margin: 20px auto 10px;
          }

          .googeplay_img {
            display: block;
            width: 80%;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .signup {
    .el-main {
      height: 60%;
    }
  }
}

@media screen and (max-width: 393px) {
  .signup {
    .el-main {
      height: 67%;
    }
  }
}

@media screen and (max-width: 375px) {
  .signup {
    .el-main {
      height: 60%;
    }
  }
}

@media screen and (max-width: 360px) {
  .signup {
    .el-main {
      height: 65%;
    }
  }
}
</style>
