import Vue from "vue";
import VueRouter from "vue-router";
import SignupPage from "../views/signupPage";
import GiftPage from "../views/giftPage";
import ResultPage from "../views/resultPage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/signupPage",
  },
  {
    path: "/signupPage",
    name: "signupPage",
    component: SignupPage,
  },
  {
    path: "/giftPage",
    name: "giftPage",
    component: GiftPage,
  },
  {
    path: "/resultPage",
    name: "resultPage",
    component: ResultPage,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
